@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* fix Mui Table Pagination component margins issue by bootstrap */
.MuiTablePagination-root p {
  margin: 0px;
}
/* remove autofill colors on chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}

.option-field svg:hover {
  cursor: pointer;
  color: #c8101d;
}

#create-btn .MuiButton-startIcon {
  margin-right: 0;
  margin-left: 0;
}

.disabled-field input, .disabled-field textarea {
  -webkit-text-fill-color: #212b36 !important;
}

.css-havevq-MuiSvgIcon-root:hover {
  color: red;
}

.image-spacing {
  margin-top: 20px;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 0 20px !important;
}

.css-11zwbe3-MuiPaper-root-MuiDialog-paper img {
  margin-bottom: 20px;
}

.phone_input input {
  width: 100% !important;
  height: 49px !important;
}

.phone_input .country-list {
  width: 260px !important;
}

.phone_input .country-list .search {
  width: 227px;
}
.images {
    display: flex;
    position: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: center;
  }
  
  .image {
    margin: 0.3rem 0.3rem ;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  .image button {
    position: absolute;
    
    right: 0;
    
    cursor: pointer;
    border: none;
    color: white;
    background: transparent;
  }